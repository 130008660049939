import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UnmappedDocCountRefreshService {
  unmappedDocCount$ = new BehaviorSubject<any>(null);

  sendMessage(message: any): void {
    this.unmappedDocCount$?.next(message);
  }
}
